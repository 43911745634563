import React from 'react';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Printjob from './Printjob';
import {withTranslation} from 'react-i18next';
import PrintjobAdd from "./PrintjobAdd";
import PrintjobUploaded from "./PrintjobUploaded";
import InfoBar from "./InfoBar";
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";

const styles = {
  documentsHeader: {
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 8,
  },
  emptyPrintjobInfoBar: {
    marginTop: 0,
    marginBottom: 5,
  }
};

function Printjobs(props) {
  const {colorEnabled, printjobs, t, uploadedFiles, classes} = props;
  var elems = [];

  elems.push(
    <Divider variant="inset" key={elems.length} />
  );

  printjobs.forEach(function(printjob) {
    const doc = printjob.documents[0];
    var errorMessage = null;

    if (doc.is_failed) {
      errorMessage = t('printjobInvalidFormat');
    } else if (printjob.is_failed) {
      errorMessage = t('printjobFailed');
    }

    elems.push(
      <Printjob
        key={elems.length}
        errorMessage={errorMessage}
        disabled={props.disabled || doc.is_failed}
        onSettingsChanged={(changes) => {props.onSettingsChanged(changes, printjob.id)}}
        orderUrl={props.orderUrl}
        updateOrder={props.updateOrder}
        id={printjob.id}
        name={doc.name}
        pageCount={doc.page_count}
        inProgress={!['printed', 'created'].includes(printjob.status) && !printjob.is_failed}
        printed={printjob.status === 'printed'}
        colorEnabled={colorEnabled}
        isGrayscale={printjob.settings.is_grayscale}
        isDuplex={printjob.settings.is_duplex}
        document={doc}
        printer={props.printer}
      />
    );
    elems.push(
      <Divider variant="inset" key={elems.length} />
    );
  });

  uploadedFiles.forEach((uploadedFile) => {
    elems.push(
      <PrintjobUploaded
        key={elems.length}
        uploadedFile={uploadedFile}
      />
    );

    elems.push(
      <Divider variant="inset" key={elems.length} />
    );
  });

  return(
    <React.Fragment>
      <Typography variant="body2" className={classes.documentsHeader}>
        {t('documents')}
      </Typography>
      <List>
        {elems}
      </List>
      {props.emptyMessage &&
        <InfoBar
          caption={t('orderEmpty')}
          type="info"
          className={classes.emptyPrintjobInfoBar}
        />
      }
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <PrintjobAdd
          orderUrl={props.orderUrl}
          updateOrder={props.updateOrder}
          uploadFiles={props.uploadFiles}
          disabled={props.disabled}
        />
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(withTranslation()(Printjobs));
