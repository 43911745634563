// TODO REFACTOR THIS
import React from 'react';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Circle, GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button/Button';
import DirectionsIcon from '@material-ui/icons/Directions';
import Typography from '@material-ui/core/Typography';
import pink from '@material-ui/core/colors/pink'
import grey from '@material-ui/core/colors/grey'
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string'
import Location from '../utils/Location';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon';
import PrinterMapSearch from './PrinterMapSearch';
import FilterButton from './FilterButton';


const styles = theme => ({
  root: {
    position: 'relative',
    marginTop: '20px',
    height: 400,
    '& .gm-style-iw-c': {
      borderRadius: 0,
      maxWidth: 'none !important',
      maxHeight: 'none !important',
      padding: '12px !important',
    },
    '& .gm-style-iw-c > .gm-style-iw-d': {
      overflow: 'visible !important',
      maxWidth: 'none !important',
    },
    '& .gm-style-iw-c > button': {
      top: '0px !important',
      right: '0px !important',
    },
  },
  navigateButton: {
    position: 'absolute',
    zIndex: 1,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  navigateButtonIcon: {
    marginLeft: theme.spacing(1),
  },
  loaderCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
    marginTop: 20,
  }

});

const stylesPrinterMarker =  theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 30,
  },
  subtitle1: {
    paddingLeft: 5,
    paddingRight: 18,
    lineHeight: 1.2,
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: -12,
  },
  subtitle2: {
    paddingLeft: 5,
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  printerAvailable: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  printerUnavailable: {
    color: 'inherit',
    fontWeight: 400,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  pictogramGrid: {
    width: 'auto',
  },
  button: {
    borderRadius: 2,
    textTransform: 'none',
    paddingLeft: 4,
    paddingRight: 4,
    '& > span': {
      display: 'inline-block',
      textAlign: 'center',
    },
  },
  buttonOutlined: {
    color: grey['900'],
  },
  span: {
    minWidth: 24,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

const enabledMark = process.env.PUBLIC_URL + '/images/markers_printer_enabled.svg';
const disabledMark = process.env.PUBLIC_URL + '/images/markers_printer_disabled.svg';

const isInIframe = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

const PrinterMarker = withStyles(stylesPrinterMarker)(
  (withTranslation()(props => {
    const buttonLinkProps = path => {
      if (isInIframe) {
        return {
          href: path,
          component: 'a',
          target: '_blank',
        };
      } else {
        return {
          to: path,
          component: Link,
        }
      }
    };

    return (
      <Marker
        clusterer={props.clusterer}
        position={props.position}
        icon={{
          url: props.enabled ? enabledMark : disabledMark,
          size: {width: 60, height: 80},
          anchor: {x: 30, y: 65},
          scaledSize: {width: 60, height: 80}
        }}
        onClick={props.clickable ? () => { props.setOpenedMarker(props.printerId); } : null}
        title={props.printerName}
      >
        {props.openedMarkerId === props.markerId &&
        <InfoWindow
          onCloseClick={() => { props.setOpenedMarker(props.printerId); }}
          disableAutoPan="false"
        >
          <div>
            <Typography
              className={classNames(props.classes.subtitle1, props.classes.ellipsis)}
              variant="h6"
              data-cy="infoWindow.printerName"
            >
              {props.printerName}
            </Typography>
            <Typography
              className={classNames(props.classes.subtitle2, props.classes.ellipsis)}
              variant="inherit"
            >
              {props.printerAddress}
            </Typography>
            <Divider className={props.classes.divider} />
            <Grid container className={props.classes.container} spacing={10}>
              <Grid item xs={4}>
                {props.enabled &&
                <div
                  className={props.classes.printerAvailable}
                >
                  {props.t('printerAvailable')}
                </div>
                }
                {!props.enabled &&
                  <div
                    className={props.classes.printerUnavailable}
                  >
                    {props.t('printerUnavailable')}
                  </div>
                }
              </Grid>
              <Grid item container xs={8} spacing={8} alignItems="flex-start">
                <Grid item container direction="column" alignItems="center" className={props.classes.pictogramGrid}>
                  <Icon color="inherit">invert_colors</Icon>
                  <span className={props.classes.span} >{props.t('bw')}</span>
                </Grid>
                {props.isColor &&
                <Grid item container direction="column" alignItems="center" className={props.classes.pictogramGrid}>
                  <Icon color="inherit">palette</Icon>
                  <span className={props.classes.span} >{props.t('color')}</span>
                </Grid>
                }
                {props.isCopier &&
                  <Grid item container direction="column" alignItems="center" className={props.classes.pictogramGrid}>
                    <Icon color="inherit">scanner</Icon>
                    <span className={props.classes.span} >{props.t('scan')}</span>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Divider className={props.classes.divider} />
            <Grid container spacing={10}>
              <Grid item container xs={6} justifyContent="flex-start">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classNames(props.classes.button, props.classes.buttonOutlined)}
                  {...buttonLinkProps(`/printers/${props.printerId}`)}
                  fullWidth
                  data-cy="infoWindow.details"
                >
                  {props.t('details')}
                </Button>
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  className={props.classes.button}
                  {...buttonLinkProps(`/printers/${props.printerId}/orders/new`)}
                  fullWidth
                  data-cy="infoWindow.printHere"
                >
                  {props.t('printHere')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </InfoWindow>
        }
      </Marker>
    );
  })));

const containerStyle = {
  width: '100%',
  height: '400px'
};

const libraries = ["places"];

function ZGoogleMap({apiKey, printers}) {
  const [map, setMap] = React.useState(null)
  const [center, setCenter] = React.useState({lat: 0, lng: 0})
  const [deviceLocation, setDeviceLocation] = React.useState(null)
  const [zoom, setZoom] = React.useState(10)
  const [canUpdateHistory, setCanUpdateHistory] = React.useState(false)
  const [openedMarkerId, setOpenedMarkerId] = React.useState(null)
  const [showOnlyColorPrinters, setShowOnlyColorPrinters] = React.useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries: libraries,
  })

  const setMapLocation = (newCenter, newZoom) => {
    setCenter(newCenter)
    setZoom(newZoom)
  };

  const updateHistory = (center, zoom) => {
    window.history.replaceState(null, null, '?l=' + Location.encodeUrlString(center, zoom));
  }

  const onIdle = () => {
    if (printers.length > 1 && canUpdateHistory) {
      updateHistory(map.getCenter(), map.getZoom());
    }

    // Enable update history after map change
    setCanUpdateHistory(true);

    setMapLocation(map.getCenter(), map.getZoom());
  }

  const locateDevice = (centerToLocation = true) => {
    if (deviceLocation === null) {
      // Get current device position only if it was not set before
      if (navigator.geolocation && printers.length > 1) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            setDeviceLocation(location)

            if (centerToLocation) {
              setMapLocation(location, 10);
            }
          },
          (error) => {
            console.error(error);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      }
    } else {
      // Otherwise center map to user location
      setMapLocation(deviceLocation, 10);
    }
  }

  const onLoad = React.useCallback(function callback(map) {
    const queryLocation = queryString.parse(window.location.search).l;

    // Prevent adding location query string on initial map load
    setCanUpdateHistory(false);

    if (queryLocation !== undefined && Location.validate(queryLocation)) {
      // Map needs to be centered on query string location if it exist

      const location = Location.decodeUrlString(queryLocation);

      setMapLocation(location.center, location.zoom);

      locateDevice(false);
    } else {
      // Otherwise map needs to fits locations of all printers

      let bounds = new window.google.maps.LatLngBounds();
      printers.forEach(function(printer) {
        bounds.extend(printer.maps.location);
      });
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const offset = 0.001;
        let center = printers[0].maps.location;
        bounds.extend({lat: center.lat + offset, lng: center.lng + offset});
        bounds.extend({lat: center.lat - offset, lng: center.lng - offset});
      }

      map.fitBounds(bounds);
      locateDevice();
    }

    setMap(map);
  }, [])

  const mClusterSize1 = {
    url: process.env.PUBLIC_URL + 'images/cluster/marker_cluster.svg',
    height: 60,
    width: 60,
    textColor: 'white',
    anchorIcon: [55,30],
    anchorText: [10,11],
    textSize: 14
  };

  const mClusterSize2 = {...mClusterSize1, ...{textSize:12}};
  const mClusterSize3 = {...mClusterSize1, ...{textSize:9}};

  // Markers are not clickable if map is loaded in printer details
  const clickable = printers.length > 1;

  return isLoaded ? (
    <>
      {printers.length > 1 && (
        <>
          <PrinterMapSearch
            locateDevice={locateDevice}
            setMapLocation={setMapLocation}
            fitBounds={ (bounds) => { map.fitBounds(bounds) }}
          />
          <FilterButton
            showOnlyColorPrinters={ (toggle) => setShowOnlyColorPrinters(toggle) }
          />
        </>
      )}
    <GoogleMap
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onIdle={onIdle}
      mapContainerStyle={containerStyle}
    >
      <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            styles={[mClusterSize1,mClusterSize2,mClusterSize3]}
      >
        {(clusterer) =>
            printers.filter((printer) => printer.isColor || !showOnlyColorPrinters).map((printer) => (
              <PrinterMarker
                key={printer.id}
                position={printer.maps.location}
                clusterer={clusterer}
                printerId={printer.id}
                printerName={printer.name}
                printerAddress={printer.address}
                isColor={printer.isColor}
                isCopier={printer.isCopier}
                clickable={clickable}
                enabled={printer.enabled}
                markerId={printer.id}
                openedMarkerId={openedMarkerId}
                setOpenedMarker={setOpenedMarkerId}
              />
            ))
        }
      </MarkerClusterer>
      {deviceLocation &&
      <React.Fragment>
        <Marker
          position={deviceLocation}
          icon={{
            path: 'M0 0m-0,0a0,0,0 1,0 0,0a 0,0 0 1,0 -0,0zM0 0m-4,0a4,4,0 0,1 8,0a 4,4 0 0,1 -8,0z',
            fillColor: pink.A400,
            fillOpacity: 1,
            anchor: new window.google.maps.Point(0,0),
            strokeWeight: 0,
            scale: 2
          }}
        />
        <Marker
          position={deviceLocation}
          icon={{
            path: 'M0 0m-7,0a7,7,0 1,0 14,0a 7,7 0 1,0 -14,0zM0 0m-4,0a4,4,0 0,1 8,0a 4,4 0 0,1 -8,0z',
            fillColor: '#ffffff',
            fillOpacity: 1,
            anchor: new window.google.maps.Point(0,0),
            strokeWeight: 0,
            scale: 2
          }}
        />
        <Circle
          center={deviceLocation}
          radius={100.0}
          options={{
            strokeWeight: 0,
            fillColor: pink.A400,
            fillOpacity: 0.20,
          }}
        />
      </React.Fragment>
      }
    </GoogleMap>
    </>
  ) : <></>
}

const PrinterMap = ({apiKey, classes, printers, t, loading}) => {

  if (loading) {
    return (
      <Card className={classes.loaderCard}>
        <CircularProgress/>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      {printers.length === 1 &&
        <Button
          variant="contained"
          color="default"
          className={classes.navigateButton}
          href={'https://www.google.com/maps/dir/?api=1&destination=' + printers[0].maps.location.lat + ',' + printers[0].maps.location.lng + '&travelmode=walking'}
          target='_blank'
          data-cy="printerMap.navigate"
        >
          {t('navigate')}
          <DirectionsIcon className={classes.navigateButtonIcon} />
        </Button>
      }
      <ZGoogleMap
        apiKey={apiKey}
        printers={printers}
      />
    </Card>
  )
};

export default withStyles(styles)(withTranslation()(PrinterMap));
