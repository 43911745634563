import React, {Component} from 'react';
import PrinterMap from './PrinterMap';

class PrinterMapWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      language: 'en',
      printers: [],
    };

    this.getPrinter = this.getPrinter.bind(this);
  }

  render() {
    return (
      <PrinterMap
        printers={this.state.printers}
        apiKey={process.env.REACT_APP_GMAPS_EMBED_API_KEY}
        loading={this.state.loading}
      />
    )
  }

  getPrinter() {
    return fetch(this.apiUrl('/printers')).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState({printers: json, loading: false});
    }).catch(function(ex) {
      console.log('parsing failed', ex);
    });
  }

  apiUrl(path) {
    return process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + path;
  }

  componentDidMount() {
    this.getPrinter();
  }
}

export default PrinterMapWidget;
