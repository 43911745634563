export default class Location {
  static validate(location) {
    const parts = location.split(',');

    if (parts.length !== 3) {
      return false;
    }

    parts.forEach(part => {
        if (!/^([-]?\d+\.\d+)$/.test(part)) {
          return false;
        }
    });

    return true;
  }

  static encodeUrlString(center, zoom) {
    return center.toUrlValue() + ',' + zoom;
  }

  static decodeUrlString(urlString) {
    const parts = urlString.split(',');

    const latitude = parseFloat(parts[0]);
    const longitude = parseFloat(parts[1]);
    const zoom = parseInt(parts[2]);

    return {
      center: new window.google.maps.LatLng({
        lat: latitude,
        lng: longitude,
      }),
      zoom: zoom,
    };
  }
}