import React, {Component} from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Switch from "@material-ui/core/Switch/Switch";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import PrintjobPreview from "./PrintjobPreview";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  avatar: {
    marginRight: '12px'
  },
  text: {
    overflow: 'hidden',
    maxWidth: '100%',
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '80px'
    },
  },
  container: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    top: '-3px',
    left: '-3px'
  },
  settingsFormControl: {
    marginBottom: theme.spacing(3),
  }
});

class Printjob extends Component {
  constructor(props) {
    super(props)

    this.state = {
      settingsOpened: false,
      copies: props.document.copies,
    };

    this.remove = this.remove.bind(this);
    this.handleCopiesChanged = this.handleCopiesChanged.bind(this);
    this.handleCopiesBlur = this.handleCopiesBlur.bind(this);
    this.increaseCopies = this.increaseCopies.bind(this);
    this.decreaseCopies = this.decreaseCopies.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.closeSettings = this.closeSettings.bind(this);
  }

  settingsChanged(changes) {
    this.props.onSettingsChanged(changes);
  }

  remove() {
    fetch(this.props.orderUrl('/printjobs/' + this.props.id + ''), {
      method: 'DELETE',
    }).then(function(response) {
      return response.json();
    }).then((json) => {
      this.props.updateOrder(json);
    });
  }

  handleCopiesChanged(event) {
    let copies = event.target.value;

    if (copies !== '') {
      copies = Number.parseInt(copies);
    }

    // Accept only empty or valid values
    if (copies === '' || (Number.isInteger(copies) && copies > 0)) {
      this.setState({copies: copies});
    }
  }

  handleCopiesBlur() {
    // Replace empty value with one
    if (this.state.copies === '') {
      this.setState({copies: 1})
    }
  }

  increaseCopies() {
    this.setState({copies: this.state.copies + 1});
  }

  decreaseCopies() {
    if (this.state.copies > 1) {
      this.setState({copies: this.state.copies - 1});
    }
  }

  toggleSettings() {
    this.setState({settingsOpened: !this.state.settingsOpened});
  }

  closeSettings() {
    this.setState({settingsOpened: false}, () => {
      // Update document if it is necessary
      if (
        (
          this.state.copies !== this.props.document.copies ||
          this.state.range !== this.props.document.range
        )
      ) {
        this.settingsChanged({
          copies: this.state.copies,
          range: this.state.range,
        });
      }
    });
  }

  render() {
    const disabled = this.props.failed || this.props.disabled;
    const { classes, colorEnabled, t } = this.props;

    return(
      <ListItem
        data-cy="printjob"
      >
        <div className={classes.container}>
          <Avatar className={classes.avatar}>
          { this.props.errorMessage !== null ?
            <ErrorIcon />
            : (this.props.printed ? <DoneIcon />
                : <InsertDriveFileIcon />
              )
          }
          </Avatar>
          { this.props.inProgress && <CircularProgress className={classes.progress} size={46}/> }
        </div>
        <ListItemText
          className={classes.text}
          data-cy="printjob.text"
          primary={this.props.name}
          secondary={
            <>
              {this.props.errorMessage &&
                <span data-cy="printjob.error">
                  {this.props.errorMessage}
                </span>
              }
              {!this.props.errorMessage &&
                <>
                  {t('pageCount')}:{' '}
                  {this.props.pageCount === null &&
                    <CircularProgress style={{marginLeft: 4, marginRight: 4}} size={12}/>
                  }
                  {this.props.pageCount !== null &&
                    <span data-cy="printjob.text.pages">
                      {this.props.pageCount}
                    </span>
                  }
                  {' '} / {t('copies')}:
                  <span data-cy="printjob.text.copies">
                    {this.props.document.copies}
                  </span>
                  {this.props.isDuplex && ' / ' + t('duplex')}
                </>
              }
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={this.remove}
            disabled={disabled && !this.props.document.is_failed}
            data-cy="printjob.removeButton"
          >
            <CloseIcon />
          </IconButton>

          <Button
            onClick={this.toggleSettings}
            disabled={disabled}
            variant="contained"
            color="primary"
            data-cy="printjob.settingsButton"
          >
            {t('settings')}
          </Button>

          <Dialog
            onClose={this.closeSettings}
            open={this.state.settingsOpened}
            scroll='body'
            data-cy={"settingsDialog"}
          >
            <DialogTitle>
              {t('printSettings')}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <FormControl fullWidth className={classes.settingsFormControl}>
                    <InputLabel>{t('copies')}</InputLabel>
                    <Input
                      type="text"
                      value={this.state.copies || ''}
                      onChange={this.handleCopiesChanged}
                      onBlur={this.handleCopiesBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={this.state.copies === 1}
                            onClick={this.decreaseCopies}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <IconButton
                            onClick={this.increaseCopies}
                          >
                            <AddIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <List>
                    <ListItem>
                      <ListItemText primary={t('settingsColor')} />
                      <ListItemSecondaryAction>
                        <Switch
                          disabled={disabled || !colorEnabled}
                          onChange={(event, checked) => this.settingsChanged({ is_grayscale: !checked })}
                          checked={!this.props.isGrayscale}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={t('settingsDuplex')} />
                      <ListItemSecondaryAction>
                        <Switch
                          disabled={disabled}
                          onChange={(event, checked) => this.settingsChanged({ is_duplex: checked })}
                          checked={this.props.isDuplex}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item sm={6}>
                  <PrintjobPreview
                    id={this.props.id}
                    orderUrl={this.props.orderUrl}
                    pageCount={this.props.pageCount}
                    isGrayscale={this.props.isGrayscale}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeSettings}
                color="primary"
                variant="contained"
                autoFocus
                data-cy="settingsDialog.okButton"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}

export default withStyles(styles)(withTranslation()(Printjob));
